/* eslint no-param-reassign: ["error", { "props": false }] */

const handleMessage = iframeElement => event => {
  if (event.source !== iframeElement.contentWindow) {
    // Keep only message from target iframe
    return;
  }

  if (!event.data) {
    console.warn('No payload in received message!');
    return;
  }

  const { data: payload } = event;

  // Set iframe height
  if (payload.height && payload.height !== iframeElement.style.height) {
    iframeElement.style.height = `${payload.height}px`;
  }

  const hash = window.location.hash.substring(1);
  const currentState = new URLSearchParams(hash);

  // Store current iframe content pathname in main location hash
  if (payload.location?.pathname) {
    // console.log(payload.location);

    currentState.set('pathname', payload.location?.pathname);
    window.location.hash = `#${currentState.toString()}`;
  }
};

const getVerticalPadding = ({ top, bottom } = {}) => {
  const output = { top: 0, bottom: 0 };

  if (top) {
    const topElement = document.querySelector(top);
    output.top = topElement?.getBoundingClientRect()?.bottom || 0;
  }
  if (bottom) {
    const bottomElement = document.querySelector(bottom);
    output.bottom = bottomElement?.getBoundingClientRect()?.top || 0;
  }

  output.sum = output.top + output.bottom;

  return output;
};

const setupIframe = ({
  basePath = '',
  iframeSelector = 'iframe',
  iframeElement = document.querySelector(iframeSelector),

  verticalPaddingSelectors = {},
}) => {
  if (!iframeElement) {
    // eslint-disable-next-line no-console
    console.error('Unable to find target frame, check `iframeSelector` parameter.');
    return;
  }

  if (typeof window === 'undefined') {
    // eslint-disable-next-line no-console
    console.error('Window is undefined!');
    return;
  }

  { // Init frame src
    const hash = window.location.hash.substring(1);
    const currentState = new URLSearchParams(hash);
    const pathName = currentState.get('pathname');
    const location = `${basePath}${pathName}`;

    if (pathName && iframeElement.src !== location) {
      iframeElement.src = location;
    }
  }

  window.addEventListener('message', handleMessage(iframeElement));

  { // Manage & propagate scroll event
    const handleScroll = () => {
      const { top } = iframeElement.getBoundingClientRect();
      const verticalOffset = getVerticalPadding(verticalPaddingSelectors).top;
      const value = (top < verticalOffset) ? (top * -1 + verticalOffset) : 0;
      iframeElement.contentWindow.postMessage({ type: 'scrollEvent', value });
    };
    setInterval(handleScroll, 1000);
    document.addEventListener('scroll', handleScroll);
  }

  { // Manage & propagate resize event
    const handleResize = () => {
      iframeElement.contentWindow.postMessage({
        type: 'resizeEvent',
        vh: window.innerHeight,
        vw: window.innerWidth,
        maskedHeight: getVerticalPadding(verticalPaddingSelectors).sum,
      });
    };
    setInterval(handleResize, 1000);
    window.addEventListener('resize', handleResize);
  }
};

export default setupIframe;
